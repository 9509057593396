import { createStore } from 'vuex';
import themeLayout from './modules/themeLayout/actionCreator';
import auth from './modules/authentication/axios/actionCreator';
import role from './modules/system/role/actionCreator';
import menu from './modules/system/menu/actionCreator';
import user from './modules/system/user/actionCreator';
import chartContent from './modules/chartContent/actionCreator';
import articles from './modules/articles/actionCreator';
import group from './modules/group/actionCreator';
import building from './modules/building/actionCreator';
import hotline from './modules/hotline/actionCreator';
import camera from './modules/camera/actionCreator';
import escape from './modules/escape/actionCreator';
import resident from './modules/resident/actionCreator';
import fireInstructions from './modules/fire-instructions/actionCreator';
import buildingManager from './modules/building-manager/actionCreator';
export default createStore({
  modules: {
    themeLayout,
    auth,
    role,
    menu,
    user,
    chartContent,
    articles,
    group,
    building,
    resident,
    hotline,
    escape,
    camera,
    fireInstructions,
    buildingManager,
  },
});
