import mutations from '../building/mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { notification } from 'ant-design-vue';
import { checkResponse } from '@/util/common-utils';
import { showConfirm } from '@/util/confirm';

const state = () => ({
  listAll: [],
  loading: false,
  api: ConstantAPI.building,
  action: null,
});

const actions = {
  async getAll({ state, commit }) {
    const response = await DataService.callApi(state.api.GET_ALL);
    commit('setListAll', response.data);
  },
  async getAllByGroup({ state, commit }, groupId) {
    const response = await DataService.callApi(
      state.api.GET_ALL_BY_GROUP,
      null,
      { groupId }
    );
    commit('setListAll', response.data);
  },
  async create({ state }, payload) {
    try {
      const response = await DataService.callApi(state.api.CREATE, payload);
      checkResponse(response, () => {
        notification.success({
          message: 'Thông báo',
          description: 'Thêm mới Tòa nhà thành công',
          duration: 4,
        });
      });
    } catch (err) {
      console.log(err);
    }
  },
  async update({ state }, payload) {
    try {
      const response = await DataService.callApi(state.api.UPDATE, payload);
      checkResponse(response, () => {
        notification.success({
          message: 'Thông báo',
          description: 'Sửa Tòa nhà thành công',
          duration: 4,
        });
      });
    } catch (err) {
      console.log(err);
    }
  },
  async delete({ state }, rows) {
    try {
      if (!rows.length) {
        notification.error({
          message: 'Thông báo',
          description: 'Không có bản ghi được chọn',
          duration: 4,
        });
        return false;
      }
      if (await showConfirm('Bạn có chắc chắn muốn xóa không?')) {
        const response = await DataService.delete(
          state.api.DELETE.url + rows.map((e) => e.id).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Xóa Tòa nhà thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async changeAction({ commit }, action) {
    commit('changeAction', action);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
