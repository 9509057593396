import store from '@/vuex/store';

const guard = async (from) => {
  if (store.getters.allMenu.includes(from.path)) {
    return true;
  }
  return { path: '404' };
};

const adminRoutes = [
  {
    path: '',
    redirect: () => {
      return '/home';
    },
  },
  {
    path: 'home',
    name: 'dashboard',
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ '@/view/manager/home/Home.vue'
      ),
  },
  {
    path: 'settings',
    name: 'settings',
    component: () =>
      import(
        /* webpackChunkName: "Settings" */ '@/view/pages/settings/Settings.vue'
      ),
    children: [
      {
        path: 'profile-settings',
        name: 'profile-settings',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Profile.vue'
          ),
      },
      {
        path: 'profile-settings/profile',
        name: 'set-profile',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Profile.vue'
          ),
      },
      {
        path: 'profile-settings/account',
        name: 'set-account',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Account.vue'
          ),
      },
      {
        path: 'profile-settings/password',
        name: 'set-password',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Password.vue'
          ),
      },
    ],
  },
  {
    path: 'system',
    name: 'system',
    children: [
      {
        path: 'menu',
        name: 'menu-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/menu/Menu.vue'
          ),
      },
      {
        path: 'role',
        name: 'role-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/role/Role.vue'
          ),
      },
    ],
  },
  {
    path: 'manager',
    name: 'manager',
    children: [
      {
        path: 'user',
        name: 'user-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/user/User.vue'
          ),
      },
      {
        path: 'group',
        name: 'group-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/group/Group.vue'
          ),
      },
      {
        path: 'resident',
        name: 'resident-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/resident/Resident.vue'
          ),
      },
      {
        path: 'building-manager',
        name: 'building-manager',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/building-manager/BuildingManager.vue'
          ),
      },
    ],
  },
  {
    path: 'content',
    name: 'content',
    children: [
      {
        path: 'procedure',
        name: 'procedure',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/procedure/Procedure.vue'
          ),
      },
      {
        path: 'legislation',
        name: 'legislation',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/legislation/Legislation.vue'
          ),
      },
      {
        path: 'introduce',
        name: 'introduce',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/introduce/Introduce.vue'
          ),
      },
      {
        path: 'topics',
        name: 'topics',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/topics/list.vue'
          ),
      },
      {
        path: 'banner',
        name: 'banner',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/banner/Banner.vue'
          ),
      },
      {
        path: 'article',
        name: 'article',
        children: [
          {
            path: 'management',
            name: 'management',
            beforeEnter: [guard],
            component: () =>
              import(
                /* webpackChunkName: "Manager" */ '@/view/manager/article/article/Article.vue'
              ),
          },
          {
            path: 'approval',
            name: 'approval',
            beforeEnter: [guard],
            component: () =>
              import(
                /* webpackChunkName: "Manager" */ '@/view/manager/article/approval/Approval.vue'
              ),
          },
          {
            path: 'publish',
            name: 'publish',
            beforeEnter: [guard],
            component: () =>
              import(
                /* webpackChunkName: "Manager" */ '@/view/manager/article/publish/Publish.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: 'category',
    name: 'category-management',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager" */ '@/view/manager/category/Category.vue'
      ),
  },
  {
    path: 'qa',
    name: 'qa',
    children: [
      {
        path: 'frequently-asked',
        name: 'frequently-asked',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/qAndA/frequentlyAsked/FrequentlyAsked.vue'
          ),
      },
      {
        path: 'q-and-a',
        name: 'q-and-a',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/qAndA/qAndA/QAndA.vue'
          ),
      },
    ],
  },
  {
    path: 'config',
    name: 'config',
    children: [
      {
        path: 'majors',
        name: 'majors',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/document/list.vue'
          ),
      },
      {
        path: 'groups',
        name: 'groups',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/document/list.vue'
          ),
      },
      {
        path: 'fields',
        name: 'fields',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/document/list.vue'
          ),
      },
      {
        path: 'types',
        name: 'types',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/document/list.vue'
          ),
      },
      {
        path: 'agency',
        name: 'agency',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/document/list.vue'
          ),
      },
    ],
  },
  {
    path: 'building',
    name: 'building',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager" */ '@/view/manager/building/Building.vue'
      ),
  },
  {
    path: 'event',
    name: 'event',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager" */ '@/view/manager/event/Event.vue'
      ),
  },
  {
    path: 'camera',
    name: 'camera',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager" */ '@/view/manager/camera/Cameras.vue'
      ),
  },
  {
    path: 'news',
    name: 'news',
    children: [
      {
        path: 'map-escape',
        name: 'map-escape',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/news/mapEscape.vue'
          ),
      },
      {
        path: 'list-hotline',
        name: 'list-hotline',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/news/listHotline.vue'
          ),
      },
      {
        path: 'fire-instructions',
        name: 'fire-instructions',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/news/fire-instructions/FireInstructions.vue'
          ),
      },
    ],
  },
];

export default adminRoutes;
