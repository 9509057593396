import mutations from '../camera/mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { notification } from 'ant-design-vue';
import { checkResponse } from '@/util/common-utils';
import { showConfirm } from '@/util/confirm';

const state = () => ({
  list: [],
  loading: false,
  api: ConstantAPI.camera,
  action: null,
});

const actions = {
  async create({ state }, payload) {
    try {
      let isSuccess = false;
      const response = await DataService.callApi(state.api.CREATE, payload);
      checkResponse(response, () => {
        isSuccess = true;
        notification.success({
          message: 'Thông báo',
          description: 'Thêm mới Camera thành công',
          duration: 4,
        });
      });
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async update({ state }, payload) {
    try {
      let isSuccess = false;
      const response = await DataService.callApi(state.api.UPDATE, payload);
      checkResponse(response, () => {
        isSuccess = true;
        notification.success({
          message: 'Thông báo',
          description: 'Sửa Camera thành công',
          duration: 4,
        });
      });
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async delete({ state }, rows) {
    try {
      if (!rows.length) {
        notification.error({
          message: 'Thông báo',
          description: 'Không có bản ghi được chọn',
          duration: 4,
        });
        return false;
      }
      if (await showConfirm('Bạn có chắc chắn muốn xóa không?')) {
        const response = await DataService.delete(
          state.api.DELETE.url + rows.map((e) => e.id).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Xóa Camera thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async changeAction({ commit }, action) {
    commit('changeAction', action);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
