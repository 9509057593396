export default {
  changeAction(state, action) {
    state.action = action;
  },
  setListAll(state, data) {
    state.listAll = data.map((e) => ({
      ...e,
      label: e.name + ' - ' + e.floor + ' tầng',
    }));
  },
};
