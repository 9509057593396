import mutations from '../resident/mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { notification } from 'ant-design-vue';
import { checkResponse } from '@/util/common-utils';
import { showConfirm } from '@/util/confirm';

const state = () => ({
  listAll: [],
  loading: false,
  api: ConstantAPI.resident,
  action: null,
});

const actions = {
  async create({ state }, payload) {
    try {
      const response = await DataService.callApi(state.api.CREATE, payload);
      checkResponse(response, () => {
        notification.success({
          message: 'Thông báo',
          description: 'Thêm mới Cư dân thành công',
          duration: 4,
        });
      });
    } catch (err) {
      console.log(err);
    }
  },
  async update({ state }, payload) {
    try {
      const response = await DataService.callApi(state.api.UPDATE, payload);
      checkResponse(response, () => {
        notification.success({
          message: 'Thông báo',
          description: 'Sửa Cư dân thành công',
          duration: 4,
        });
      });
    } catch (err) {
      console.log(err);
    }
  },
  async delete({ state }, rows) {
    try {
      if (!rows.length) {
        notification.error({
          message: 'Thông báo',
          description: 'Không có bản ghi được chọn',
          duration: 4,
        });
        return false;
      }
      if (await showConfirm('Bạn có chắc chắn muốn xóa không?')) {
        const response = await DataService.delete(
          state.api.DELETE.url + rows.map((e) => e.id).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Xóa Cư dân thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async changeAction({ commit }, action) {
    commit('changeAction', action);
  },
  async getResidentByPhone({ state }, phone) {
    const response = await DataService.callApi({
      method: 'GET',
      url: state.api.GET_BY_PHONE.url + phone,
    });
    return response.data;
  },
  async exportTemplate({ state, commit }) {
    try {
      commit('setLoading', true);
      notification.info({
        message: 'Thông báo',
        description: 'Đang tiến hành tải file',
        duration: 4,
      });
      const response = await DataService.getConfig(
        state.api.EXPORT_TEMPLATE.url,
        null,
        null,
        'blob'
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Mẫu import dữ liệu cư dân.xlsx');
      document.body.appendChild(link);
      link.click();
      notification.success({
        message: 'Thông báo',
        description: 'Thành công',
        duration: 4,
      });
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
