const CMS_SERVICE = '/cms-service';
const AUTH_SERVICE = '/authorization-service';
const USER_SERVICE = '/user-service';
export default {
  report_service: {
    OVERVIEW: {
      url: CMS_SERVICE + '/overview',
      method: 'GET',
    },
    OVERVIEW_LINE: {
      url: CMS_SERVICE + '/chart',
      method: 'GET',
    },
  },
  role: {
    GET_ALL: {
      url: USER_SERVICE + '/role/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: USER_SERVICE + '/role',
      method: 'GET',
    },
    CREATE: {
      url: USER_SERVICE + '/role',
      method: 'POST',
    },
    UPDATE: {
      url: USER_SERVICE + '/role',
      method: 'PUT',
    },
    DELETE: {
      url: USER_SERVICE + '/role',
      method: 'DELETE',
    },
  },
  role_menu: {
    GET_BY_ROLE: {
      url: USER_SERVICE + '/role-menu',
      method: 'GET',
    },
  },
  user_role: {
    GET_BY_USER: {
      url: USER_SERVICE + '/user-role',
      method: 'GET',
    },
  },
  group: {
    ALL: {
      url: CMS_SERVICE + '/group/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/group',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/group',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/group',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/group',
      method: 'DELETE',
    },
  },
  menu: {
    SEARCH: {
      url: USER_SERVICE + '/menu',
      method: 'GET',
    },
    CREATE: {
      url: USER_SERVICE + '/menu',
      method: 'POST',
    },
    UPDATE: {
      url: USER_SERVICE + '/menu',
      method: 'PUT',
    },
    DELETE: {
      url: USER_SERVICE + '/menu',
      method: 'DELETE',
    },
  },
  user: {
    SEARCH: {
      url: USER_SERVICE + '/user',
      method: 'GET',
    },
    CREATE: {
      url: USER_SERVICE + '/user',
      method: 'POST',
    },
    UPDATE: {
      url: USER_SERVICE + '/user',
      method: 'PUT',
    },
    DELETE: {
      url: USER_SERVICE + '/user',
      method: 'DELETE',
    },
    CHECK_USERNAME_EXISTED: {
      url: USER_SERVICE + '/user/check',
      method: 'GET',
    },
    GET_USER_INFO: {
      url: USER_SERVICE + '/user/get-user-info',
      method: 'GET',
    },
    GET_NOTIFICATIONS: {
      url: CMS_SERVICE + '/notify',
      method: 'GET',
    },
    MARK_READ_NOTIFICATION: {
      url: CMS_SERVICE + '/notify/seen',
      method: 'PATCH',
    },
    PATCH_UPDATE: {
      url: USER_SERVICE + '/user',
      method: 'PATCH',
    },
  },
  client: {
    GET_ALL: {
      url: USER_SERVICE + '/clients',
      method: 'GET',
    },
  },
  auth: {
    LOGIN: {
      url: AUTH_SERVICE + '/oauth/token',
      method: 'POST',
    },
    REFRESH_TOKEN: {
      url: AUTH_SERVICE + '/oauth/refresh-token',
      method: 'POST',
    },
    LOGOUT: {
      url: AUTH_SERVICE + '/oauth/revoke',
      method: 'DELETE',
    },
    PATCH_UPDATE: {
      url: USER_SERVICE + '/user',
      method: 'PATCH',
    },
  },
  upload_service: {
    UPLOAD: {
      url: CMS_SERVICE + '/upload',
      method: 'POST',
    },
  },
  articles: {
    ALL: {
      url: CMS_SERVICE + '/article/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/article',
      method: 'GET',
    },
    SEARCH_APPROVE: {
      url: CMS_SERVICE + '/article/approve',
      method: 'GET',
    },
    SEARCH_PUBLIC: {
      url: CMS_SERVICE + '/article/publish',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/article',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/article',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/article',
      method: 'DELETE',
    },
    UN_PUBLISH: {
      url: CMS_SERVICE + '/article/un-publish',
      method: 'PATCH',
    },
    APPROVAL: {
      url: CMS_SERVICE + '/article/approve',
      method: 'PATCH',
    },
    PUBLISH: {
      url: CMS_SERVICE + '/article/publish',
      method: 'PATCH',
    },
  },
  chart: {
    DOMAIN_IP: {
      url: CMS_SERVICE + '/chart/account-domain-ip',
      method: 'GET',
    },
    ORGANIZATION: {
      url: CMS_SERVICE + '/chart/organization',
      method: 'GET',
    },
    STATUS: {
      url: CMS_SERVICE + '/chart/status',
      method: 'GET',
    },
    SUCCESS_STATUS: {
      url: CMS_SERVICE + '/chart/success-or-not',
      method: 'GET',
    },
    SUMMARY: {
      url: CMS_SERVICE + '/chart/summary',
      method: 'GET',
    },
    MANAGE_DATA: {
      url: CMS_SERVICE + '/agency/get-chart',
      method: 'GET',
    },
    PROVIDE_DATA: {
      url: CMS_SERVICE + 'agency/get-chart-dataset',
      method: 'GET',
    },
    FIELD_DATA: {
      url: CMS_SERVICE + '/fields/chart-field-dataset',
      method: 'GET',
    },
    TOTAL_FILE: {
      url: CMS_SERVICE + '/file/total-files',
      method: 'GET',
    },
    TOTAL_FILE_UPLOAD: {
      url: CMS_SERVICE + '/file/total-files-upload-by-month',
      method: 'GET',
    },
    TOTAL_FILE_DOWNLOAD: {
      url: CMS_SERVICE + '/file/total-files-download-by-month',
      method: 'GET',
    },
    TOTAL_AGENCIES_UPLOADED: {
      url: CMS_SERVICE + '/file/total-agencies-uploaded',
      method: 'GET',
    },
    TOP_FILE_UPLOAD: {
      url: CMS_SERVICE + '/file/top-10',
      method: 'GET',
    },
    TOP_AGENCIES: {
      url: CMS_SERVICE + '/file/top-10-agencies',
      method: 'GET',
    },
    FILE_EXTENSION: {
      url: CMS_SERVICE + '/file/files-extension',
      method: 'GET',
    },
  },
  topics: {
    GET_ALL: {
      url: CMS_SERVICE + '/topics/all',
      method: 'GET',
    },
    GET_BY_CONDITIONS: {
      url: CMS_SERVICE + '/topics',
      method: 'GET',
    },
    GET_BY_ID: {
      url: CMS_SERVICE + '/topics/',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/topics/create',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/topics/update',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/topics/',
      method: 'DELETE',
    },
  },
  banners: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/banners',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/banners/all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/banners/create',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/banners/update',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/banners/',
      method: 'DELETE',
    },
  },
  escape: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/escape-plan',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/escape-plan/all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/escape-plan',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/escape-plan',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/escape-plan/',
      method: 'DELETE',
    },
  },
  building: {
    GET_ALL: {
      url: CMS_SERVICE + '/building/get-all',
      method: 'GET',
    },
    GET_ALL_BY_GROUP: {
      url: CMS_SERVICE + '/building/group',
      method: 'GET',
    },
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/building',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/building',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/building',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/building/',
      method: 'DELETE',
    },
  },
  hotline: {
    GET_ALL: {
      url: CMS_SERVICE + '/hotline/get-all',
      method: 'GET',
    },
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/hotline',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/hotline',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/hotline',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/hotline/',
      method: 'DELETE',
    },
  },
  camera: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/camera',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/camera',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/camera',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/camera/',
      method: 'DELETE',
    },
  },
  resident: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/resident',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/resident',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/resident',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/resident/',
      method: 'DELETE',
    },
    GET_BY_PHONE: {
      url: CMS_SERVICE + '/resident/phone/',
      method: 'GET',
    },
    EXPORT_TEMPLATE: {
      url: CMS_SERVICE + '/resident/create-excel',
      method: 'GET',
    },
    IMPORT_DATA: {
      url: CMS_SERVICE + '/resident/import-excel',
      method: 'POST',
    },
  },
  fireInstructions: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/article',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/article',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/article',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/article/',
      method: 'DELETE',
    },
    GET_BY_ID: {
      url: CMS_SERVICE + '/article/',
      method: 'GET',
    },
  },
  buildingManager: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/building-manager',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/building-manager',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/building-manager',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/building-manager/',
      method: 'DELETE',
    },
    GET_BY_PHONE: {
      url: CMS_SERVICE + '/building-manager/phone/',
      method: 'GET',
    },
    EXPORT_TEMPLATE: {
      url: CMS_SERVICE + '/building-manager/create-excel',
      method: 'GET',
    },
    IMPORT_DATA: {
      url: CMS_SERVICE + '/building-manager/import-excel',
      method: 'POST',
    },
  },
  event: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/event',
      method: 'GET',
    },
    GET_BY_ID: {
      url: CMS_SERVICE + '/event/',
      method: 'GET',
    },
  },
};
