import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  searchSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  getAllSuccess(state, data) {
    state.listAll = data;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.group = {
      id: null,
      name: '',
      description: '',
    };
  },
  setFormValue(state, { group, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.group = {
      ...group,
    };
  },
};
