import packageInfo from '../../../demoData/dashboardChartContent.json';
import mutations from './mutations';
import { DataService } from '@/dataService/dataService';
import ConstantAPI from '@/config/ConstantAPI';
import { TABLE_LINE_CHART } from '@/config/Constant';

const state = () => ({
  forcastData: null,
  lineLoading: false,
  youtubeSubscribeData: null,
  registerLoading: false,
  closeDealData: null,
  cdLoading: false,
  recentDealData: null,
  recLoading: false,
  socialTrafficData: null,
  soLoading: false,
  twitterOverviewData: null,
  twLoading: false,
  instagramOverviewData: null,
  inLoading: false,
  linkdinOverviewData: null,
  liLoading: false,
  cashFlowData: null,
  cfLoading: false,
  incomeData: null,
  icLoading: false,
  performanceData: null,
  perLoading: false,
  trafficChanelData: null,
  tcLoading: false,
  deviceData: null,
  dvLoading: false,
  landingPageData: null,
  lpLoading: false,
  regionData: null,
  reLoading: false,
  generatedData: null,
  geLoading: false,
  topSaleData: null,
  tsLoading: false,
  locationData: null,
  loLoading: false,
  error: null,
  api: ConstantAPI.report_service,
  overview: {
    user: 0,
    ekyc: 0,
    land: 0,
    classifieds: 0,
    cv: 0,
    recruitment: 0,
  },
  recruitment: {
    label: ['0', '4', '8', '12', '16', '20', '24'],
    data: ['0', '0', '0', '0', '0', '0', '0'],
    preventTotal: 0,
    total: 0,
    type: 'trending-up',
  },
  classifieds: {
    label: ['0', '4', '8', '12', '16', '20', '24'],
    data: ['0', '0', '0', '0', '0', '0', '0'],
    preventTotal: 0,
    total: 0,
    type: 'trending-up',
  },
  land: {
    label: ['0', '4', '8', '12', '16', '20', '24'],
    data: ['0', '0', '0', '0', '0', '0', '0'],
    preventTotal: 0,
    total: 0,
    type: 'trending-up',
  },
  cv: {
    label: ['0', '4', '8', '12', '16', '20', '24'],
    data: ['0', '0', '0', '0', '0', '0', '0'],
    preventTotal: 0,
    total: 0,
    type: 'trending-up',
  },
  userRegister: {
    user: [],
    ekyc: [],
  },
});

const actions = {
  async loadOverview({ state, commit }) {
    try {
      const response = await DataService.callApi(state.api.OVERVIEW);
      return commit('loadOverviewSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async loadLineChart({ state, commit }, { type }) {
    try {
      state.lineLoading = true;
      const responses = await Promise.all(
        Object.keys(TABLE_LINE_CHART).map(
          (k) =>
            new Promise((resolve, reject) =>
              DataService.callApi(state.api.OVERVIEW_LINE, null, {
                type,
                table: k,
              })
                .then((r) => resolve({ data: r.data, chart: k.toLowerCase() }))
                .catch((e) => reject(e))
            )
        )
      );
      commit('loadLineChartSuccess', {
        type,
        responses,
      });
      state.lineLoading = false;
    } catch (err) {
      state.lineLoading = false;
      console.log(err);
    }
  },
  async loadUserRegister({ state, commit }, type) {
    try {
      state.registerLoading = true;
      const response = await DataService.callApi(
        state.api.OVERVIEW_LINE,
        null,
        {
          type,
          table: 'USER',
        }
      );
      return commit('loadUserRegisterSuccess', { type, data: response.data });
    } catch (err) {
      console.log(err);
      state.registerLoading = false;
    }
  },
  async twitterOverviewGetData({ commit }) {
    const { month } = packageInfo.twitterOverview;
    try {
      commit('twitterOverviewBegin');
      commit('twitterOverviewSuccess', month);
    } catch (err) {
      commit('twitterOverviewErr', err);
    }
  },
  async twitterOverviewFilterData({ commit }, value) {
    try {
      commit('twitterOverviewBegin');
      setTimeout(() => {
        commit('twitterOverviewSuccess', packageInfo.twitterOverview[value]);
      }, 100);
    } catch (err) {
      commit('twitterOverviewErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
